import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Container from '@material-ui/core/Container';
import FrontHero from 'components/hero/front';
import FrontHeroMobile from 'components/hero/FrontHeroMobile';
import {
  TitleCaptionText,
  VideoBlock,
  LargeImageBlock,
  HousingSlideshow,
  TextLeftImageRight,
  ThreeColumns,
} from 'components/slices';
import Spacer from 'components/spacer';
import withWidth from '@material-ui/core/withWidth';
import { compose, isTabletView, isDesktopView } from 'utils';

function Home(props) {
  const { data, width } = props;
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
  if (!doc) return null;
  return (
    <Layout
      headerMenuType={
        doc.node.header_menu_type ? doc.node.header_menu_type : false
      }
    >
      {isDesktopView(width) && <FrontHero slides={doc.node.slides} auto />}
      {isTabletView(width) && (
        <FrontHeroMobile
          title={doc.node.mobile_banner_title[0].text}
          image={doc.node.mobile_imageSharp.childImageSharp.fluid}
        />
      )}
      {doc.node.body.map((item, i) => {
        switch (item.type) {
          case 'title__caption__text':
            return (
              <Spacer key={i}>
                <Container maxWidth="xl">
                  <TitleCaptionText data={item.primary} logo={true} />
                </Container>
              </Spacer>
            );
          case 'video_block':
            return (
              <Spacer key={i}>
                <VideoBlock data={item.primary} />
              </Spacer>
            );
          case 'large_image_block':
            return (
              <Spacer key={i}>
                <LargeImageBlock data={item.primary} />
              </Spacer>
            );
          case 'housing_slideshow':
            return (
              <Spacer key={i}>
                <HousingSlideshow data={item.fields} />
              </Spacer>
            );
          case 'text_left_image_right':
            return (
              <Spacer key={i}>
                <TextLeftImageRight data={item.primary} />
              </Spacer>
            );
          case 'three_column':
            return (
              <Spacer key={i}>
                <ThreeColumns data={item.primary} fields={item.fields} />
              </Spacer>
            );
          default:
            return null;
        }
      })}
    </Layout>
  );
}

Home.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.string,
};

export default compose(withWidth())(Home);

export const query = graphql`
  query {
    prismic {
      allHomepages {
        edges {
          node {
            header_menu_type
            slides {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              slide_title
            }
            mobile_banner_title
            mobile_image
            mobile_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            body {
              ... on PRISMIC_HomepageBodyTitle__caption__text {
                type
                label
                primary {
                  show_hayfield_logo
                  title
                  caption
                  text
                }
              }
              ... on PRISMIC_HomepageBodyVideo_block {
                type
                label
                primary {
                  body1
                  embedded_video
                  title
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyLarge_image_block {
                type
                label
                primary {
                  link_title
                  show_logo_ident
                  show_logo_text
                  title
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  link {
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        uid
                        type
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyHousing_slideshow {
                type
                label
                fields {
                  housing_development {
                    _linkType
                    ... on PRISMIC_Development {
                      title
                      thumbnail_pre_title
                      thumbnail_image
                      thumbnail_imageSharp {
                        childImageSharp {
                          fluid(maxWidth: 1000, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                      address {
                        postcode
                        town
                      }
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyText_left_image_right {
                type
                label
                primary {
                  title
                  caption
                  text
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyThree_column {
                type
                label
                primary {
                  title
                  caption
                }
                fields {
                  caption
                  image
                  title
                  link {
                    ... on PRISMIC_Home_type {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Article {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Standard_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Basic_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Homepage {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Development {
                      title
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                    ... on PRISMIC_Contact_page {
                      _meta {
                        id
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
