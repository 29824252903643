import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { useTransition, useSpring, animated, config } from 'react-spring';
import posed from 'react-pose';
import SplitText from 'react-pose-text';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export const Slide = styled(animated.div)`
  height: 100%;
  width: 100%;
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
`;

export const StyledSlider = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
`;

const Intro = posed.div({
  exit: {
    x: '-100%',
  },
  enter: {
    x: '0%',
    beforeChildren: true,
    staggerChildren: 50,
  },
});

const charPoses = {
  exit: { opacity: 0 },
  enter: { opacity: 1 },
};

const styles = {
  uppercase: {
    textTransform: 'uppercase',
  },
};

const Image = ({ url, duration }) => {
  const props = useSpring({
    from: {
      transform: 'scale(1) translateX(0%)',
    },
    to: {
      transform: 'scale(1.2) translateX(0%)',
    },
    config: {
      duration: duration - 200,
    },
  });
  return <Slide style={{ ...props }} image={url} />;
};

Image.propTypes = {
  url: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
};

// Slider
function FrontHero(props) {
  const { auto, slides, classes } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  // Set image slides
  const slidesObj = [];
  slides.map((item, index) => {
    slidesObj.push({
      id: index,
      url: item.image.url,
      title: item.slide_title[0].text,
    });
  });

  // Transitions
  const transitions = useTransition(
    slidesObj[currentIndex],
    item => {
      return item.id;
    },
    {
      from: {
        opacity: 0,
      },
      enter: {
        opacity: 1,
      },
      leave: {
        opacity: 0,
      },
      config: config.molasses,
    }
  );

  useEffect(() => {
    if (auto) {
      const interval = setInterval(() => {
        setCurrentIndex(i => {
          return (i + 1) % slidesObj.length;
        });
      }, 10000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <React.Fragment>
      <StyledSlider>
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={{ ...props }}>
            <Image url={item.url} duration={10000} />
            <Box
              position="absolute"
              style={{ width: '100%', height: '100%' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Intro initialPose="exit" pose="enter">
                <Typography
                  component="h1"
                  variant="h1"
                  color="secondary"
                  className={classes.uppercase}
                  align="center"
                >
                  <SplitText charPoses={charPoses} initialOffset={5}>
                    {item.title}
                  </SplitText>
                </Typography>
              </Intro>
            </Box>
          </animated.div>
        ))}
      </StyledSlider>
    </React.Fragment>
  );
}

FrontHero.propTypes = {
  auto: PropTypes.bool,
  slides: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FrontHero);
